/// <reference path="../include.ts" />

namespace page_top {

	import Logger = alm.debug.Logger;
	import GlobalNavigation = project_commons.GlobalNavigation;

	export class Application {

		// --------------------------------------------------
		//
		// CONSTRUCTOR
		//
		// --------------------------------------------------

		constructor() {
			Logger.setLevelByQuery('log');
		}





		// --------------------------------------------------
		//
		// METHOD
		//
		// --------------------------------------------------

		public run(): void {
			// detecting language
			const directories = window.location.href.split('/');
			const lang = directories[directories.length - 2];
			jQuery('html').addClass('lang-' + lang);
			trace('[Application] lang :', lang);

			// navigation
			this.globalNavigation = new GlobalNavigation();
			this.globalNavigation.ready();
			this.globalNavigation.show();




			var effect_move = 50; // どのぐらい要素を動かすか(px)
			var effect_time = 800; // エフェクトの時間(ms) 1秒なら1000
			// フェードする前のcssを定義
			$('.scroll-fade').css({
				opacity: 0,
				transform: 'translateY(' + effect_move + 'px)',
				transition: effect_time + 'ms'
			});
			(<any>jQuery(window)).on('scroll load', function () {
				var effect_pos = 100; // 画面下からどの位置でフェードさせるか(px)
				var scroll_top = $(this).scrollTop();
				var scroll_btm = scroll_top + $(this).height();
				effect_pos = scroll_btm - effect_pos;
				// effect_posがthis_posを超えたとき、エフェクトが発動
				$('.scroll-fade').each(function () {
					var this_pos = $(this).offset().top;
					// console.log(effect_pos);
					// console.log(this_pos);
					if (effect_pos > this_pos) {
						$(this).css({
							opacity: 1,
							transform: 'translateY(0)'
						});
					}
				});
			});



			(<any>jQuery('#slick-uservoice')).slick({
				rows: 4,
				autoplay: true,
				dots: true,
				dotsClass: 'slick-dots',
				appendDots: $('.slick-dots'),
				prevArrow: $('.slick-prev-button'),
				nextArrow: $('.slick-next-button'),
			});
			(<any>jQuery('#slick-top')).slick({
				autoplay: true,
				dots: false,
				arrows: false,
			});
			(<any>jQuery('#slick-top-desktop')).slick({
				autoplay: true,
				dots: false,
				arrows: false,
				// cssEase:escape
				fade:true,
				easing:"easeOutSine",
			});
			(<any>jQuery('#slick-flyer')).slick({
				// autoplay: true,
				// // dots: false,
				// // arrows: false,
				// // cssEase:escape
				// fade:true,
				// easing:"easeOutSine",
				// slidesToShow: 3,
				// slidesToScroll: 1,
				// // autoplay: true,
				// autoplaySpeed: 2000,
				slidesToShow: 5,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 2000,
				centerMode:true
			});
			(<any>jQuery('#slick-flyer-mobile')).slick({
				// autoplay: true,
				// // dots: false,
				// // arrows: false,
				// // cssEase:escape
				// fade:true,
				// easing:"easeOutSine",
				// slidesToShow: 3,
				// slidesToScroll: 1,
				// // autoplay: true,
				// autoplaySpeed: 2000,
				slidesToShow: 3,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 2000,
				centerMode: false
			});
			(<any>jQuery('.js-modal-vimeo')).modalVideo();
			(<any>jQuery('.js-modal-btn')).modalVideo();

		}






		// --------------------------------------------------
		//
		// MEMBER
		//
		// --------------------------------------------------

		private globalNavigation: GlobalNavigation;
	}
}
