/* ---------- project dependencies ---------- */

/// <reference types="jquery" />
/// <reference types="alumican" />
/// <reference types="alumican.jquery" />
/// <reference types="alumican.drawer" />


/* ---------- project scripts ---------- */
/// <reference path="view/GlobalNavigation.ts" />
