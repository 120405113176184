/// <reference path="../include.ts" />

namespace project_commons {

	import View = alm.view.View;
	import WindowWatcher = alm.browser.WindowWatcher;
	import Drawer = alm.drawer.Drawer;
	import DrawerPosition = alm.drawer.DrawerPosition;
	import WindowWatcherEvent = alm.browser.WindowWatcherEvent;
	import TweenCSS = alm.util.TweenCSS;
	import Easing = alm.math.Easing;
	import Cmd = alm.util.Cmd;

	export class GlobalNavigation extends View<JQuery> {

		// --------------------------------------------------
		//
		// CONSTRUCTOR
		//
		// --------------------------------------------------

		constructor() {
			super(jQuery('#navigation-bar'));
			this.initialize();
		}





		// --------------------------------------------------
		//
		// METHOD
		//
		// --------------------------------------------------

		protected implInitialize(): JQuery {
			const view = this.getView();

			this.scrollOffsetY = 20;
			this.offsetNavigationBarHeight = true;
			this.scrollDuration = 1.5;
			this.scrollEasing = 'easeInOutQuart';

			// drawer
			this.drawer = new Drawer(jQuery('#drawer-content'), DrawerPosition.left);
			this.drawer.getContentView().find('.section a').each((index: number, element: HTMLElement): void => {
				this.setScrollAction(element);
			});

			// navigation
			this.isFixed = false;
			this.offsetY = 0;
			jQuery('a').each((index: number, element: HTMLElement): void => {
				this.setScrollAction(element);
			});
			WindowWatcher.addEventListener(WindowWatcherEvent.SCROLL, this.windowScrollHandler);
			WindowWatcher.addEventListener(WindowWatcherEvent.RESIZE, this.windowResizeHandler);
			WindowWatcher.start();
			this.applyScroll();

			this.topSection = jQuery('#top');
			this.background = view;
			this.backgroundOpacity = 0;
			this.updateBackgroundScrollThreshold();
			this.checkBackgroundVisibility(false);

			return view;
		}

		protected implReady(): void {
		}

		protected implFinalize(): void {
		}

		protected implShow(view: JQuery, useTransition: boolean): cmd.Command {
			return TweenCSS.fadeIn(view, useTransition ? 500 : 0, Easing.easeOutQuart, 'block', false, false);
		}

		protected implHide(view: JQuery, useTransition: boolean): cmd.Command {
			return TweenCSS.fadeOut(view, useTransition ? 500 : 0, Easing.easeOutQuart, true, false, false);
		}

		public setOffsetY(offsetY: number): void {
			this.offsetY = offsetY;
			this.applyScroll();
			this.getView().css('top', this.isFixed ? 0 : this.offsetY);
		}

		private applyScroll(): void {
			this.setSticky(WindowWatcher.getScrollTop() >= this.offsetY);
		}

		private setSticky(isFixed: boolean): void {
			if (this.isFixed == isFixed) return;
			this.isFixed = isFixed;
			trace('[GlobalNavigation] fixed :', this.isFixed);

			const view = this.getView();

			if (this.isFixed) {
				view.addClass('fixed');
				view.removeClass('floating');
				view.css({ top: 0 });
			} else {
				view.addClass('floating');
				view.removeClass('fixed');
				view.css({ top: this.offsetY });
			}
		}

		private windowScrollHandler = (event: WindowWatcherEvent): void => {
			this.applyScroll();
			this.checkBackgroundVisibility();
		};

		private windowResizeHandler = (event: WindowWatcherEvent): void => {
			this.updateBackgroundScrollThreshold();
			this.checkBackgroundVisibility();
		};




		private setScrollAction(element: HTMLElement): void {
			const link: JQuery = jQuery(element);
			const anchor = link.attr('href');
			if (anchor.indexOf('#') != -1) {
				link.attr('href', 'javascript:void(0)');
				link.on('click', { anchor: anchor }, this.anchorButtonClickHandler);
			}
		}

		private anchorButtonClickHandler = (event: JQuery.ClickEvent): void => {
			window['closeDrawer']();
			const anchor: string = event.data.anchor;
			const target = jQuery(anchor);

			const targetPosition = target.offset().top - (this.offsetNavigationBarHeight ? this.getView().outerHeight() : 0) - this.scrollOffsetY;
			jQuery('html, body').animate({ scrollTop: targetPosition }, this.scrollDuration * 1000, this.scrollEasing);
		};





		public setScrollOffsetY(offsetY: number, offsetNavigationBarHeight: boolean = true): void {
			this.scrollOffsetY = offsetY;
			this.offsetNavigationBarHeight = offsetNavigationBarHeight;
		}

		public setScrollSpeed(duration: number, easing: string): void {
			this.scrollDuration = duration;
			this.scrollEasing = easing;
		}





		private updateBackgroundScrollThreshold(): void {
			this.backgroundScrollThreshold = this.topSection.height();
		}

		private checkBackgroundVisibility(useTransition: boolean = true): void {
			if (WindowWatcher.getScrollTop() > this.backgroundScrollThreshold) {
				this.setBackgroundVisibility(true, useTransition);
			} else {
				this.setBackgroundVisibility(false, useTransition);
			}
		}

		private setBackgroundVisibility(isVisible: boolean, useTransition: boolean = true): void {
			if (this.isBackgroundVisible == isVisible) return;
			this.isBackgroundVisible = isVisible;
			trace('[GlobalNavigation] background visible :', this.isBackgroundVisible);

			Cmd.stop(this.backgroundTween);
			this.backgroundTween = new cmd.Tween(this, { backgroundOpacity: isVisible ? 1 : 1 }, null, useTransition ? 500 : 0, Easing.easeOutQuart, null, (): void => {
				this.applyBackground();
			});
			this.backgroundTween.execute();
		}

		private applyBackground(): void {
			this.background.css('background-color', 'rgba(255, 255, 255, ' + this.backgroundOpacity + ')');
		}




		// --------------------------------------------------
		//
		// VARIABLE
		//
		// --------------------------------------------------

		private drawer: Drawer;

		private offsetY: number;
		private isFixed: boolean;

		private scrollOffsetY: number;
		private offsetNavigationBarHeight: boolean;

		private scrollDuration: number;
		private scrollEasing: string;

		private background: JQuery;
		private backgroundOpacity: number;
		private backgroundTween: cmd.Command;
		private backgroundScrollThreshold: number;
		private isBackgroundVisible: boolean;
		private topSection: JQuery;
	}
}
